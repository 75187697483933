import axios from 'axios';
import {
  getLocalStorageItem,
  localStorageKeys,
  getPathnameLocalStorageItem,
} from 'hooks';
import { routes } from 'routes/RouteConstants';

const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'content-type': 'application/json',
    'X-CSRFTOKEN':
      'hIESQXzSEZxPitbMXJTmcpHf6k0Q4LUXHS8Ms9pxN3MBrquuwKbIgpGqvIgihFWy',
  },
});

// const handleLogoutRedirectUser = () => {
//   window.location = routes.LOGIN.pathname;
//   localStorage.removeItem(localStorageKeys.AUTH_TOKEN);
// };

AxiosInstance.interceptors.request.use(async (config) => {
  const authToken = await getLocalStorageItem(localStorageKeys.AUTH_TOKEN);
  if (authToken) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${authToken}`,
    };
  }
  // if (config && config.data && config.method === 'get') {
  //   config.url = config.url + getPathnameLocalStorageItem();
  //   config.data = { ...config.data };
  // }
  return config;
});

AxiosInstance.interceptors.response.use(
  (response) => response.data,
  async (error) => {
    if (
      error?.response?.data?.error_messages === 'Unauthorized' &&
      error.response.data.status === 401
    ) {
      // handleLogoutRedirectUser();
    }

    return Promise.reject(error.response.data);
  }
);

export default AxiosInstance;
