import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from 'utils';
import { axios } from 'utils';

export const appendQueryParams = (url, data) => {
  let resp = url + '?';
  if (data)
    Object.keys(data).forEach((key) => {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(data[key])) {
        resp += `${key}=${
          typeof data[key] === 'string'
            ? data[key]?.replaceAll('&', '%26')
            : data[key]
        }&`;
      }
    });
  return resp;
};

const FetchActiveUsers = (data) => {
  const method = 'GET';
  let url = appendQueryParams(`api/v1/admin/manage-employee/active`, {
    ...data?.selectedActiveFilters,
    ...data?.activePagination,
  });
  return axios({
    method,
    url,
  });
};

export const useGetAllActiveUsersQuery = (payload) => {
  return useQuery(['ACTIVE_USER', payload], () => FetchActiveUsers(payload), {
    enabled: Boolean(payload),
  });
};
const FetchInActiveUsers = (data) => {
  const method = 'GET';
  let url = appendQueryParams(`api/v1/admin/manage-employee/inactive`, {
    ...data?.selectedInActiveFilters,
    ...data?.inActivePagination,
  });
  return axios({
    method,
    url,
  });
};

export const useGetAllInActiveUsersQuery = (payload) => {
  return useQuery(
    ['INACTIVE_USER', payload],
    () => FetchInActiveUsers(payload),
    {
      enabled: Boolean(payload),
    }
  );
};

const GrantAccessUser = (data) => {
  const method = 'POST';
  let url = `api/v1/admin/manage-employee/grant-access`;
  return axios({ method, url, data });
};

export const GrantAccessUserQuery = () => {
  const queryClient = useQueryClient();
  const responseToast = useToast();

  return useMutation(GrantAccessUser, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(['INACTIVE_USER']);
      queryClient.invalidateQueries(['ACTIVE_USER']);
      responseToast.toastSuccess(
        response.message || 'Grant Access Successfully'
      );
    },
    // onError: (error) =>
    //   responseToast.toastError(error, 'Error while trying to..'),
  });
};
const BulkSearchData = (data) => {
  const method = 'POST';
  let url = `api/v1/admin/manage-employee/bulk/search`;
  return axios({ method, url, data });
};

export const BulkSearchDataQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(BulkSearchData, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(['INACTIVE_USER']);
    },
    // onError: (error) =>
    //   responseToast.toastError(error, 'Error while trying to..'),
  });
};
const RemoveAccessUser = (data) => {
  const method = 'POST';
  let url = `api/v1/admin/manage-employee/revoke-access`;
  return axios({ method, url, data });
};

export const RemoveAccessUserQuery = () => {
  const queryClient = useQueryClient();
  const responseToast = useToast();

  return useMutation(RemoveAccessUser, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(['INACTIVE_USER']);
      queryClient.invalidateQueries(['ACTIVE_USER']);
      responseToast.toastSuccess('Remove Successfully');
    },
    // onError: (error) =>
    //   responseToast.toastError(error, 'Error while trying to..'),
  });
};
