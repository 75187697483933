import React from 'react';
import { DialogTitle, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import Styles from './CommonDialog.module.scss';
import CloseIcon from '@mui/icons-material/Close';

const StyledCommonDialogTitle = styled(DialogTitle)(({ theme }) => ({
  display: 'flex',
  // margin: '0rem 0rem 1.5rem 0rem',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1, 0),
 // borderBottom: '1px solid #434349',
}));

const CommonDialogTitle = (props) => {
  const { onClose, title } = props;

  return (
    <StyledCommonDialogTitle>
      <span className={Styles.dialogTitleText}>{title}</span>
      {onClose ? (
        <span className="flex cursor-pointer items-center justify-center">
          <IconButton onClick={onClose}>
            <CloseIcon fontSize={'small'} />
          </IconButton>
        </span>
      ) : undefined}
    </StyledCommonDialogTitle>
  );
};

export default CommonDialogTitle;

CommonDialogTitle.defaultProps = {
  onClose: () => null,
};
