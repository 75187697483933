import React from 'react';

function PrivacyPolicy() {
  return (
    <div style={{ padding: '25px 0' }}>
      <div
        style={{
          maxWidth: '1280px',
          paddingRight: '25px',
          paddingLeft: '25px',
          marginRight: 'auto',
          marginLeft: 'auto',
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        <h1
          style={{
            color: '#000000',
            fontSize: '40px',
            fontWeight: 600,
            lineHeight: '56px',
            margin: 0,
            padding: '0 0 25px',
          }}
        >
          App Des
        </h1>
        <h3
          style={{
            fontSize: '24px',
            fontWeight: 500,
            color: '#000000',
            lineHeight: '33.6px',
            margin: 0,
          }}
        >
          Introducing Flexi Salary – the revolutionary app designed to empower
          blue-collar workers with financial flexibility and control over their
          earnings. Say goodbye to waiting for the end of the month to access
          your hard-earned money. With Flexi Salary, you can now access a
          portion of your accrued salary whenever you need it, bridging the gap
          between your financial needs and your payday.
        </h3>
        <ul>
          <li
            style={{
              listStyle: 'decimal',
              color: '#000000',
              fontSize: '24px',
              fontWeight: 500,
              lineHeight: '33.6px',
            }}
          >
            Instant Access to Your Earned Salary: Gone are the days of living
            paycheck to paycheck. Flexi Salary lets you withdraw a portion of
            your already earned salary before the end of the month. Whether it's
            unexpected expenses, emergencies, or just the desire to treat
            yourself, the power to access your money is now in your hands.
          </li>
          <li
            style={{
              listStyle: 'decimal',
              color: '#000000',
              fontSize: '24px',
              fontWeight: 500,
              lineHeight: '33.6px',
            }}
          >
            Financial Freedom and Empowerment: Flexi Salary is designed to give
            you more control over your finances. No more struggling to make ends
            meet during the final days of the month. With the flexibility to
            withdraw your earnings as needed, you can manage your budget more
            effectively and live with peace of mind.
          </li>
          <li
            style={{
              listStyle: 'decimal',
              color: '#000000',
              fontSize: '24px',
              fontWeight: 500,
              lineHeight: '33.6px',
            }}
          >
            Seamless and Secure Transactions: Our user-friendly interface
            ensures a smooth and hassle-free experience. Securely link your bank
            account and withdraw funds with a few taps. Your personal and
            financial information is safeguarded with state-of-the-art security
            measures, so you can use Flexi Salary with confidence.
          </li>
          <li
            style={{
              listStyle: 'decimal',
              color: '#000000',
              fontSize: '24px',
              fontWeight: 500,
              lineHeight: '33.6px',
            }}
          >
            Transparent Deductions: We understand the importance of clarity when
            it comes to your earnings. The amount you withdraw in advance will
            be deducted from your upcoming month's salary. The app provides a
            clear breakdown of your deductions, ensuring complete transparency
            in the process.
          </li>
        </ul>
        <h3
          style={{
            fontSize: '24px',
            fontWeight: 500,
            color: '#000000',
            lineHeight: '33.6px',
            margin: 0,
          }}
        >
          Don't wait for payday to access your hard-earned money. Experience the
          freedom and flexibility that Flexi Salary brings to your financial
          life. Download the app today and take control of your earnings like
          never before.
        </h3>
        <h3
          style={{
            fontSize: '24px',
            fontWeight: 500,
            color: '#000000',
            lineHeight: '33.6px',
            margin: '0 0 35px',
          }}
        >
          Note: Flexi Salary deducts the advance from the subsequent month's
          salary. The app is currently available to blue-collar workers and
          companies in select industries. Terms and conditions apply. Download
          Flexi Salary now and redefine how you manage your earnings!
        </h3>
      </div>
      <div
        style={{
          padding: '0 0 30px',
          maxWidth: '1280px',
          paddingRight: '25px',
          paddingLeft: '25px',
          marginRight: 'auto',
          marginLeft: 'auto',
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        <h1
          style={{
            color: '#000000',
            fontSize: '40px',
            fontWeight: 600,
            lineHeight: '56px',
            margin: 0,
            padding: '0 0 25px',
          }}
        >
          Privacy Policy
        </h1>
        <h3
          style={{
            fontSize: '24px',
            fontWeight: 500,
            color: '#000000',
            lineHeight: '33.6px',
            margin: 0,
          }}
        >
          Flexi Salary App Privacy Policy
        </h3>
        <h3
          style={{
            fontSize: '24px',
            fontWeight: 500,
            color: '#000000',
            lineHeight: '33.6px',
            margin: 0,
          }}
        >
          Here's more information the developer has provided about the kinds of
          data this app may collect and share, and security practices the app
          may follow. Data practices may vary based on your app version, use,
          region, and age.
        </h3>
        <h3
          style={{
            fontSize: '24px',
            fontWeight: 500,
            color: '#000000',
            lineHeight: '33.6px',
            margin: '35px 0',
          }}
        >
          {' '}
          Data Collected
        </h3>
        <ul>
          <li
            style={{
              listStyle: 'decimal',
              color: '#000000',
              fontSize: '24px',
              fontWeight: 500,
              lineHeight: '33.6px',
            }}
          >
            Personal Information: We collect personal information such as your
            name, email address, phone number, and banking details when you
            register for an account.
          </li>
          <li
            style={{
              listStyle: 'decimal',
              color: '#000000',
              fontSize: '24px',
              fontWeight: 500,
              lineHeight: '33.6px',
            }}
          >
            Financial Information:To facilitate early salary withdrawals, we
            collect and process information related to your earnings, salary,
            deductions, and transaction history.
          </li>
          <li
            style={{
              listStyle: 'decimal',
              color: '#000000',
              fontSize: '24px',
              fontWeight: 500,
              lineHeight: '33.6px',
            }}
          >
            {' '}
            Device Information: We automatically gather information about your
            mobile device, including your device type, operating system, unique
            device identifiers, and mobile network information.
          </li>
          <li
            style={{
              listStyle: 'decimal',
              color: '#000000',
              fontSize: '24px',
              fontWeight: 500,
              lineHeight: '33.6px',
            }}
          >
            Usage Information: We collect data about how you use the App,
            including your interactions, preferences, and browsing patterns.
          </li>
        </ul>
        <h3
          style={{
            fontSize: '24px',
            fontWeight: 500,
            color: '#000000',
            lineHeight: '33.6px',
            margin: '-15px 0 35px',
          }}
        >
          {' '}
          How We Use Your Information:
        </h3>
        <ul>
          <li
            style={{
              listStyle: 'decimal',
              color: '#000000',
              fontSize: '24px',
              fontWeight: 500,
              lineHeight: '33.6px',
            }}
          >
            Early Salary Withdrawals: We use your personal and financial
            information to process early salary withdrawals as requested by you,
            deducting the advance from your subsequent month's salary.
          </li>
          <li
            style={{
              listStyle: 'decimal',
              color: '#000000',
              fontSize: '24px',
              fontWeight: 500,
              lineHeight: '33.6px',
            }}
          >
            Customer Support: We use the information to provide customer
            support, respond to your inquiries, and assist you effectively.
          </li>
          <li
            style={{
              listStyle: 'decimal',
              color: '#000000',
              fontSize: '24px',
              fontWeight: 500,
              lineHeight: '33.6px',
            }}
          >
            Service Improvement:Your usage patterns help us enhance the App's
            features, user experience, and services.
          </li>
          <li
            style={{
              listStyle: 'decimal',
              color: '#000000',
              fontSize: '24px',
              fontWeight: 500,
              lineHeight: '33.6px',
            }}
          >
            Communication: We may send you transaction-related updates,
            promotional materials, and important notices via email, SMS, or
            in-app notifications.
          </li>
        </ul>
        <h3
          style={{
            fontSize: '24px',
            fontWeight: 500,
            color: '#000000',
            lineHeight: '33.6px',
            margin: '-15px 0 35px',
          }}
        >
          Data Protection
        </h3>
        <ul>
          <li
            style={{
              listStyle: 'decimal',
              color: '#000000',
              fontSize: '24px',
              fontWeight: 500,
              lineHeight: '33.6px',
            }}
          >
            Security Measures: We implement industry-standard security measures
            to protect your personal and financial information from unauthorized
            access, disclosure, alteration, or destruction.
          </li>
          <li
            style={{
              listStyle: 'decimal',
              color: '#000000',
              fontSize: '24px',
              fontWeight: 500,
              lineHeight: '33.6px',
            }}
          >
            Data Encryption: All sensitive data, such as financial and personal
            information, is encrypted during transmission and storage.
          </li>
        </ul>

        <h3
          style={{
            fontSize: '24px',
            fontWeight: 500,
            color: '#000000',
            lineHeight: '33.6px',
            marginTop: '-18px',
          }}
        >
          Sharing of Your Information :
        </h3>
        <br />
        <h3
          style={{
            fontSize: '24px',
            fontWeight: 500,
            color: '#000000',
            lineHeight: '33.6px',
            margin: 0,
          }}
        >
          We may share your information with :
        </h3>
        <br />
        <h3
          style={{
            fontSize: '24px',
            fontWeight: 500,
            color: '#000000',
            lineHeight: '33.6px',
            margin: 0,
          }}
        >
          - Service Providers: We engage third-party service providers to assist
          with various functions, such as payment processing and customer
          support.
        </h3>
        <h3
          style={{
            fontSize: '24px',
            fontWeight: 500,
            color: '#000000',
            lineHeight: '33.6px',
            margin: 0,
          }}
        >
          - Legal Requirements:We may disclose information to comply with
          applicable laws, regulations, legal processes, or governmental
          requests.
        </h3>
        <pre></pre>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
