import { useEffect, useState } from 'react';
import { usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
import { useSticky } from 'react-table-sticky';
import { FaSortAmountDown, FaSortAmountUp } from 'react-icons/fa';
import {
  Button,
  CircularProgress,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import TableSearch from './TableSearch';
import IndeterminateCheckbox from 'components/IndeterminateCheckbox';
import useDebounce from 'utils/useDebounce';
import { KeyboardArrowDownOutlined } from '@mui/icons-material';
import CustomPagination from './CustomPagination';
import BulkSearchModal from './BulkSearchModal';

const perPageItems = [
  {
    perPageData: 25,
    label: '25 Rows/page',
  },
  {
    perPageData: 50,
    label: '50 Rows/page',
  },
  {
    perPageData: 100,
    label: '100 Rows/page',
  },
  {
    perPageData: 500,
    label: '500 Rows/page',
  },
  {
    perPageData: 1000,
    label: '1000 Rows/page',
  },
];

export default function DataTableCustom(props) {
  const {
    data,
    columns,
    isSearch,
    inActiveBulk,
    Toolbar,
    isChecked,
    isFetching = false,
    paginationInformation,
    updateFilters,
    setGrantInactiveUser,
    placeholder,
    setSearchBulkData,
    title,
    isPagination = true,
  } = props;

  //states
  const [pageFilters, setPageFilters] = useState({
    page: 1,
    perPage: 25,
    search: '',
    sortColumn: columns[0].accessor || columns[0].Header,
    order: 'DESC',
    handlePagination: true,
  });

  
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [searchBulkModal, setSearchBulkModal] = useState(false);

  //handlers
  const handleSearchUpdate = (text) => {
    setSearchKeyword(text?.trim());
    if (pageFilters.page !== 1) handlePageFilters('page', 1);
  };
  const handleSearchBulkModalOpen = () => {
    setSearchBulkModal(true);
  };
  const handleSearchBulkModalClose = () => {
    setSearchBulkModal(false);
  };

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: pageFilters.sortColumn,
            desc: pageFilters.order === 'DESC',
          },
        ],
      },
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
      disableSortRemove: true,
    },
    useSortBy,
    usePagination,
    useSticky,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((cols) =>
        isChecked
          ? [
              // Let's make a column for selection
              {
                id: 'selection',
                // The header can use the table's getToggleAllRowsSelectedProps method
                // to render a checkbox
                Header: ({ getToggleAllRowsSelectedProps }) => (
                  <div>
                    <IndeterminateCheckbox
                      {...getToggleAllRowsSelectedProps()}
                    />
                  </div>
                ),
                // The cell can use the individual row's getToggleRowSelectedProps method
                // to the render a checkbox
                Cell: ({ row }) => {
                  return (
                    <div>
                      <IndeterminateCheckbox
                        {...row.getToggleRowSelectedProps()}
                      />
                    </div>
                  );
                },
              },
              ...cols,
            ]
          : [...cols]
      );
    }
  );

  const {
    state: { sortBy },
  } = tableInstance;
  
  useEffect(() => {
    const sortByDetails = {
      sortColumn: sortBy[0]?.id,
      order: sortBy[0]?.desc ? 'DESC' : 'ASC',
    };
    if (pageFilters) {
      setPageFilters((currentPageFilters) => ({
        ...currentPageFilters,
        ...sortByDetails,
      }));
    }
    updateFilters({
      ...pageFilters,
      ...sortByDetails,
    });
  }, [sortBy]);

  /* Debouncing the search */
  const debouncedSearchKeyword = useDebounce(searchKeyword, 500);

  const handlePageFilters = (field, value) => {
    setPageFilters &&
      setPageFilters((currentPageFilters) => ({
        ...currentPageFilters,
        [field]: value,
      }));
    updateFilters((currentPageFilters) => ({
      ...currentPageFilters,
      [field]: value,
    }));
  };

  useEffect(() => {
    handlePageFilters('search', '');
    // since we want to reset search everytime user opens the page
  }, []);

  useEffect(() => {
    // if (searchKeyword || pageFilters.search !== '') {
    handlePageFilters('search', searchKeyword);
  }, [searchKeyword]);

  const userData = tableInstance.rows.filter(
    (activeData) => activeData?.isSelected === true
  );

  useEffect(() => {
    setGrantInactiveUser?.(userData);
  }, [userData?.length]);

  useEffect(() => {
    pageFilters.page !== 1 && handlePageFilters('page', 1);
  }, [pageFilters.perPage]);
  return (
    <>
      <BulkSearchModal
        open={searchBulkModal}
        handleClose={handleSearchBulkModalClose}
        setSearchBulkData={setSearchBulkData}
      />
      <div
        className={`flex flex-col space-y-6 rounded-lg  overflow-x-hidden shadow-md bg-[#333b7a]`}
      >
        <div className="flex items-center h-[45px]  justify-between  mx-12">
          {isPagination && (
            <div className="flex items-center justify-center">
              <TableSearch
                placeholder={placeholder || 'Search'}
                value={debouncedSearchKeyword}
                onChange={handleSearchUpdate}
              />
              {inActiveBulk && (
                <Button
                  variant="standard"
                  onClick={handleSearchBulkModalOpen}
                  className="capitalize font-semibold mt-[15px] mx-12 py-auto my-auto text-center text-[16px] rounded-lg  text-white bg-[#659ECD] hover:bg-[#659ECD]"
                >
                  Bulk search
                </Button>
              )}
            </div>
          )}

          {Toolbar && <Toolbar />}
          {isPagination && (
            <div className="-py-2 z-0 pt-[20px] flex items-center justify-end rounded-lg">
              <Typography
                fontSize={'16px'}
                fontWeight={500}
                lineHeight={'22px'}
                color={'white'}
              >
                Total {paginationInformation?.totalCount} items
              </Typography>
              <CustomPagination
                count={paginationInformation?.pageCount}
                showFirstButton
                showLastButton
                page={pageFilters?.page || 1}
                onChange={(e, value) => handlePageFilters('page', value)}
              />

              <Select
                className="ml-auto"
                value={pageFilters.perPage}
                onChange={(e) => {
                  handlePageFilters('perPage', e.target.value);
                }}
                IconComponent={KeyboardArrowDownOutlined}
                renderValue={(selected) => {
                  // Find the selected option from the perPageItems array
                  const selectedOption = perPageItems.find(
                    (item) => item.perPageData === selected
                  );

                  // Return the label of the selected option
                  return selectedOption?.label || '';
                }}
                sx={{
                  '& .MuiSelect-select': {
                    paddingBlock: '.5rem',
                    background: '#659ECD',
                    border: '1px solid #659ECD',
                    fontSize: '1rem', // set font size for the label
                    color: '#FFFFFF', // set color for the label
                    lineHeight: '22px',
                    fontWeight: 400,
                    borderRadius: '12px',
                  },

                  '& .MuiSvgIcon-root': {
                    color: '#FFFFFF', // set the color of the icon
                  },
                }}
              >
                {perPageItems?.map((itemCount) => (
                  <MenuItem
                    value={itemCount?.perPageData}
                    key={`table-items-per-page-${itemCount}`}
                  >
                    {itemCount?.perPageData}
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
        </div>

        <div className="tableBoxShdow   bg-[#ECF6FF] ">
          <div
            className=" hidden text-lg my-2 bg-[#ECF6FF]"
            style={{ borderBottom: '1px solid #ecece' }}
          >
            <p className="font-semibold text-lg"> {title}</p>
          </div>
          <div className="pb-2 max-h-[50vh] bg-[#ECF6FF] overflow-y-auto">
            <table
              {...tableInstance.getTableProps()}
              className="table w-full border-collapse overflow-auto  "
              style={{ borderRight: '1px solid #333B7A' }}
            >
              <thead
                className="text-semibold  align-middle sticky top-[0px] shadow-sm"
                style={{
                  zIndex: 999,
                  borderBottom: '1px solid #333B7A',
                }}
              >
                {
                  // Loop over the header rows
                  tableInstance.headerGroups.map(
                    (headerGroup, headerGroupIndex) => {

                      return (
                        // Apply the header row props
                        <tr
                          key={`table-header-group-${headerGroupIndex}`}
                          {...headerGroup.getHeaderGroupProps()}
                          className="align-middle text-[#333B7A] font-semibold  tracking-normal px-2 "
                        >
                          {
                            // Loop over the headers in each row
                            headerGroup.headers.map((column, headerIndex) => (
                              // Apply the header cell props

                              <th
                                className={`py-6 font-bold  bg-[#ECF6FF]   ${
                                  headerIndex === 0 ? 'pl-[8px]' : ''
                                } `}
                                key={`table-header-${headerIndex}`}
                                style={{ borderRight: '1px solid #333B7A' }}
                              >
                                <div
                                  {...column.getHeaderProps(
                                    column.getSortByToggleProps({
                                      style: {
                                        width: column.width || '100px',
                                        paddingLeft: '1px',
                                        textAlign: 'center',
                                      },
                                    })
                                  )}
                                  className="flex  space-x-2"
                                >
                                  <span
                                    // className={`text-sm font-medium text-hb_blue-800 ${
                                    //   column.isSorted ? "sorted" : null
                                    // } align-middle`}
                                    className={`text-sm font-bold  align-middle px-3   ${
                                      headerIndex === 0 ? '' : ''
                                    }`}
                                  >
                                    {
                                      // Render the header
                                      column.render('Header')
                                    }
                                  </span>
                                  <span className={`align-middle`}>
                                    <span className={`align-middle`}>
                                      {column.isSorted ? (
                                        column.isSortedDesc ? (
                                          <span className="sorted-svg-icon flex">
                                            <FaSortAmountDown className="text-hb_orange-dark" />
                                          </span>
                                        ) : (
                                          <span className="sorted-svg-icon">
                                            <FaSortAmountUp className="text-hb_orange-dark" />
                                          </span>
                                        )
                                      ) : (
                                        ''
                                      )}
                                    </span>
                                  </span>
                                </div>
                                <div
                                  style={
                                    headerIndex === 0
                                      ? { width: '50px' }
                                      : {
                                          width: column.width || '100px',
                                        }
                                  }
                                >
                                  {column.Filter
                                    ? column.render('Filter')
                                    : null}
                                </div>
                              </th>
                            ))
                          }
                        </tr>
                      );
                    }
                  )
                }
              </thead>

              <tbody {...tableInstance.getTableBodyProps()}>
                {isFetching || tableInstance.rows.length === 0 ? (
                  <tr className="!h-[56px] !w-full bg-[#ECF6FF]">
                    <td colSpan={columns.length} className="text-center">
                      {isFetching ? (
                        <CircularProgress color="primary" />
                      ) : (
                        <Typography
                          variant="h6"
                          align="center"
                          color="primary"
                          className="w-full font-semibold"
                        >
                          No Data
                        </Typography>
                      )}
                    </td>
                  </tr>
                ) : (
                  <>
                    {
                      // Loop over the table rows
                      tableInstance.rows.map((row, rowIndex) => {
                        // Prepare the row for display
                        tableInstance.prepareRow(row);
                        return (
                          // Apply the row props
                          <tr {...row.getRowProps()} className="bg-[#ECF6FF]">
                            {
                              // Loop over the rows cells
                              row.cells.map((cell, idx) => {
                                // Apply the cell props
                                return (
                                  <td
                                    {...cell.getCellProps()}
                                    style={{ borderRight: '1px solid #333B7A' }}
                                    className={`text-[14px] font-medium  ${
                                      cell.column.isSorted
                                        ? 'sorted'
                                        : 'not-sorted'
                                    }  border-t-2 pt-3 align-top bg-[#ECF6FF] px-3 ${
                                      idx === 0 ? 'pl-[20px]  pt-3' : ''
                                    } ${
                                      row?.isSelected === true ? 'bg-white' : ''
                                    }`}
                                  >
                                    {
                                      // Render the cell contents
                                      cell.render('Cell')
                                    }
                                  </td>
                                );
                              })
                            }
                          </tr>
                        );
                      })
                    }
                  </>
                )}
              </tbody>
            </table>
          </div>
          {!isSearch && (
            <div className="tableBoxShdow  flex my-3 px-4  py-3  justify-between items-center">
              <p className="font-semibold text-md">
                {pageFilters.page &&
                  paginationInformation?.pageCount &&
                  `Page ${pageFilters.page} of ${paginationInformation?.pageCount}`}
              </p>

              <div className="flex gap-2">
                <Button
                  variant="contained"
                  disabled={pageFilters?.page ? pageFilters?.page === 1 : true}
                  sx={{
                    background: '#FFFFFF',

                    border: '1px solid #E5E7EB',
                    boxShadow: '0px 1px 2px rgba(16, 40, 36, 0.05)',
                    color: '#000',
                    borderRadius: '8px',
                    minWidth: '100px',
                    '&:hover': {
                      background: '#FFFFFF',
                    },
                  }}
                  onClick={() =>
                    handlePageFilters('page', pageFilters?.page - 1)
                  }
                >
                  Previous
                </Button>
                <Button
                  variant="contained"
                  disabled={
                    pageFilters?.page
                      ? pageFilters?.page ===
                          paginationInformation?.pageCount ||
                        paginationInformation?.pageCount === 0
                      : true
                  }
                  sx={{
                    background: '#FFFFFF',
                    minWidth: '100px',
                    border: '1px solid #E5E7EB',
                    boxShadow: '0px 1px 2px rgba(16, 40, 36, 0.05)',
                    color: '#000',
                    borderRadius: '8px',

                    '&:hover': {
                      background: '#FFFFFF',
                    },
                  }}
                  onClick={() =>
                    handlePageFilters('page', pageFilters?.page + 1)
                  }
                >
                  Next
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
