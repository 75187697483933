import TopNav from 'components/TopNav';

const Layout = ({ children }) => {
  return (
    <div className="grid g-container">
      <div className="header">
        <TopNav />
      </div>

      <div className="bg-white Background grow content overflow-x-auto ">
        {children}
      </div>
    </div>
  );
};

export default Layout;
