import React from 'react';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import clsx from 'clsx';

const PREFIX = 'IndeterminateCheckbox';

const classes = {
  root: `${PREFIX}-root`,
  icon: `${PREFIX}-icon`,
  checkedIcon: `${PREFIX}-checkedIcon`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
  [`& .${classes.root}`]: {
    paddingTop: '0.1rem',

    '&:hover': {
      backgroundColor: 'transparent !important',
    },
    border: 'none',

    '&$indeterminate': {
      borderRadius: '4px',

      width: '1.0rem',
      height: '1.0rem',
    },
  },
  [`& .${classes.icon}`]: {
    borderRadius: '4px',

    /* width: 16,
    height: 16, */
    /* boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)", */
    border: '2px solid #888888',
    /* backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))", */
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      /* backgroundColor: "#ebf1f5", */
    },
    /* "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    }, */
    width: '1.5rem',
    height: '1.5rem',

    marginLeft: '1rem',
  },
  [`& .${classes.checkedIcon}`]: {
    borderRadius: '4px',
    backgroundColor: '#3699FF',
    border: '2px solid #888888',
    /* backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))", */
    '&:before': {
      display: 'block',
      width: '1.5rem',
      height: '1.5rem',
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      /* backgroundColor: "#106ba3", */
    },
    width: '1.5rem',
    height: '1.5rem',
  },
});

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);
    return (
      <Root>
        <Checkbox
          type="checkbox"
          /* color="primary" */
          indeterminate={indeterminate}
          ref={resolvedRef}
          {...rest}
          disableRipple
          color="default"
          checkedIcon={
            <span className={clsx(classes.icon, classes.checkedIcon)} />
          }
          /* indeterminateIcon={
            <span className={clsx(classes.root, classes.inderminateIcon)} />
          } */
          icon={<span className={classes.icon} />}
          classes={{ root: classes.root }}
        />
      </Root>
    );
  }
);

export default IndeterminateCheckbox;
