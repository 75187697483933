import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { TopNav } from 'components';
import { ToastContainer } from 'react-toastify';
import Routes from 'routes';
import PrimaryTheme from 'styles/PrimaryTheme';
import './App.css';

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        // refetchOnWindowFocus: false,
        // cacheTime: 1000 * 60 * 7,
        // enabled: false,
        staleTime: Infinity,
      },
    },
  });

  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={PrimaryTheme}>
            <Routes />
            <ToastContainer closeButton={false} />
          </ThemeProvider>
        </StyledEngineProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
