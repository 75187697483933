import React, { useMemo, useState } from 'react';
import { Button, Grid, InputAdornment, TextField } from '@mui/material';
import TabPanel from './TabPanel';
import { BsCheck2Circle } from 'react-icons/bs';
import { MdDeleteForever } from 'react-icons/md';
import RefreshLogo from 'assets/Icons/refreshLogo.svg';
import DataTableCustom from 'components/DataTableCustom';
import {
  useGetAllActiveUsersQuery,
  useGetAllInActiveUsersQuery,
} from './ManageEmployeesQueries';
import EmployeeDataModal from './EmployeeDataModal';
import MapIcon from 'assets/Icons/mapIcon.jpg';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import RemoveAccessModal from './RemoveAccessModal';

const ManageEmployees = () => {
  // useState
  const [employeeDetailModal, setEmployeeDetailModal] = useState(false);
  const [searchBulkData, setSearchBulkData] = useState([]);
  const [removeAccessModal, setRemoveAccessModal] = useState(false);
  const [changeModal, setChangeModal] = useState('');
  const [selectedActiveFilters, setSelectedActiveFilters] = useState({});
  const [selectedInActiveFilters, setSelectedInActiveFilters] = useState({});
  const [tabValue, setTabValue] = useState('1');
  const [grantInactiveUser, setGrantInactiveUser] = useState(null);
  const [activePagination, setActivePagination] = useState({});
  const [inActivePagination, setInActivePagination] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({
    factory: '',
  });

  const handleEmployeeDetailOpenModal = () => {
    setEmployeeDetailModal(true);
  };
  const handleEmployeeDetailCloseModal = () => {
    setEmployeeDetailModal(false);
  };
  const handleRemoveAccessOpenModal = (data) => {
    setChangeModal(data);
    setRemoveAccessModal(true);
  };
  const handleRemoveAccessCloseModal = () => {
    setRemoveAccessModal(false);
  };
  const handleFactoryFilter = (event) => {
    setSelectedFilters({
      ...selectedFilters,
      factory: event.target.value,
    });
  };

  const handleActiveFilter = () => {
    setSelectedActiveFilters({
      ...selectedFilters,
    });
  };
  const handleInActiveFilter = () => {
    setSelectedInActiveFilters({
      ...selectedFilters,
    });
  };

  const { data: activeUserData, dataUpdatedAt } = useGetAllActiveUsersQuery({
    activePagination,
    selectedActiveFilters,
  });

  const { data: inActiveUserData, dataUpdatedAt: inActiveUpdatedAt } =
    useGetAllInActiveUsersQuery({
      inActivePagination,
      selectedInActiveFilters,
    });

  const { activeUserList, activePaginationInformation } = useMemo(() => {
    if (dataUpdatedAt) {
      return {
        activeUserList: activeUserData?.data?.results,
        activePaginationInformation: activeUserData?.data?.options,
      };
    } else {
      return { activeUserList: [], activePaginationInformation: {} };
    }
  }, [dataUpdatedAt]);

  const { inActiveUserList, inActivePaginationInformation } = useMemo(() => {
    if (inActiveUpdatedAt) {
      return {
        inActiveUserList: inActiveUserData?.data?.results,
        inActivePaginationInformation: inActiveUserData?.data?.options,
      };
    } else {
      return { inActiveUserList: [], inActivePaginationInformation: {} };
    }
  }, [inActiveUpdatedAt]);

  const columns = useMemo(
    () => [
      {
        Header: 'empcode',
        accessor: 'empCode',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      { Header: 'Factory code', accessor: 'factory' },
      {
        Header: 'City',
        accessor: 'city',
      },
      {
        Header: 'Phone no.',
        accessor: 'mobile',
      },
      {
        Header: 'User name',
        accessor: 'username',
      },

      {
        Header: 'Password',
        accessor: 'password',
      },
    ],
    []
  );

  const columns1 = useMemo(
    () => [
      {
        Header: 'empcode',
        accessor: 'empCode',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      { Header: 'Factory code', accessor: 'factory' },
      {
        Header: 'City',
        accessor: 'city',
      },
      {
        Header: 'Phone no.',
        accessor: 'mobile',
      },
      {
        Header: 'User name',
        accessor: 'username',
      },

      {
        Header: 'Password',
        accessor: 'password',
      },
    ],
    []
  );
  return (
    <div className="pl-[94px] pr-[95px]">
      <EmployeeDataModal
        open={employeeDetailModal}
        handleClose={handleEmployeeDetailCloseModal}
        grantInactiveUser={grantInactiveUser}
        setInactiveGrantUser={setGrantInactiveUser}
        // row={row}
      />
      <RemoveAccessModal
        open={removeAccessModal}
        handleClose={handleRemoveAccessCloseModal}
        grantInactiveUser={grantInactiveUser}
        setGrantInactiveUser={setGrantInactiveUser}
        changeModal={changeModal}
      />
      <div className="mt-[37px] flex justify-between">
        <div>
          <TabPanel
            tabvalue={tabValue}
            setTabValue={setTabValue}
            setSelectedFilters={setSelectedFilters}
          />
        </div>

        <div className="flex flex-end  gap-3">
          {tabValue === '1' ? (
            <>
              <div>
                <Button
                  variant="outlined"
                  color="primary"
                  className="leading-[150%] font-bold text-md px-8 capitalize shadow-md "
                  startIcon={<MdDeleteForever />}
                  onClick={() => handleRemoveAccessOpenModal('1')}
                >
                  Remove Access
                </Button>
              </div>

              <div>
                <Button
                  variant="outlined"
                  color="primary"
                  className="leading-[150%] font-bold text-md px-8  capitalize shadow-md"
                  startIcon={<FileDownloadOutlinedIcon />}
                  onClick={() => handleRemoveAccessOpenModal('2')}
                >
                  Download
                </Button>
              </div>
            </>
          ) : (
            <div>
              <Button
                variant="outlined"
                color="primary"
                className="leading-[150%] font-bold text-md px-8 shadow-md"
                startIcon={<BsCheck2Circle />}
                onClick={handleEmployeeDetailOpenModal}
              >
                Grant Access
              </Button>
            </div>
          )}
        </div>
      </div>
      <Grid
        container
        className="bg-[#ECF6FF] flex justify-between p-2 rounded-lg  shadow-md"
      >
        <div className="flex gap-2 justify-between items-center">
          <Grid item>
            <TextField
              sx={{
                '& .MuiOutlinedInput-root': {
                  height: '40px',
                  width: '190px',
                  borderRadius: '10px',
                },
              }}
              onInput={(e) => {
                e.target.value = e.target.value.toUpperCase();
              }}
              value={selectedFilters?.factory}
              placeholder="Factory code"
              type="text"
              onChange={handleFactoryFilter}
              className="rounded-lg"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img
                      src={MapIcon}
                      alt="Location Logo"
                      className="bg-[#ECF6FF]"
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item className="rounded-lg">
            <Button
              variant="outlined"
              sx={{ height: '39px', borderRadius: '8px' }}
              onClick={() => {
                setSelectedFilters({
                  factory: '',
                });
                setSelectedActiveFilters({});
                setSelectedInActiveFilters({});
              }}
            >
              <img
                src={RefreshLogo}
                alt="Refresh Logo"
                className="w-[24px] h-[24px] p-1"
              />
            </Button>
          </Grid>
        </div>

        <Grid conatiner className="my-4">
          <Grid item className="pl-5">
            <Button
              variant="standard"
              onClick={
                tabValue === '1' ? handleActiveFilter : handleInActiveFilter
              }
              className="leading-[150%] capitalize font-semibold text-sm px-14 py-2 text-white bg-gradient-to-b from-[#659ECD] to-[#1F305F]"
            >
              Show Data
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid conatiner className="my-4">
        {tabValue === '1' ? (
          <DataTableCustom
            data={activeUserList}
            columns={columns}
            setGrantInactiveUser={setGrantInactiveUser}
            updateFilters={setActivePagination}
            paginationInformation={activePaginationInformation}
            isSearch
            isChecked
          />
        ) : (
          <DataTableCustom
            data={
              searchBulkData?.length <= 0 ? inActiveUserList : searchBulkData
            }
            columns={columns1}
            inActiveBulk
            updateFilters={setInActivePagination}
            paginationInformation={inActivePaginationInformation}
            setGrantInactiveUser={setGrantInactiveUser}
            setSearchBulkData={setSearchBulkData}
            isSearch
            isChecked
          />
        )}
      </Grid>
    </div>
  );
};

export default ManageEmployees;
