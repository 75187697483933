import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  IconButton,
  Divider,
  Button,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { CustomTextField } from 'components';
import QRCode from 'react-qr-code';
import { RemoveAccessUserQuery } from './ManageEmployeesQueries';
import { MdDeleteForever } from 'react-icons/md';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DefaultImage from 'assets/Images/defaultImage.webp';
const RemoveAccessModal = (props) => {
  // Destructuring  data
  const {
    open,
    handleClose,
    grantInactiveUser,
    setGrantInactiveUser,
    changeModal,
  } = props;

  // states

  // queries
  const { mutate: revokeAccessEmployee } = RemoveAccessUserQuery();

  const handleRevokeAccess = () => {
    const employee_id = grantInactiveUser?.map(
      (employee) => employee?.original?.id
    );
    revokeAccessEmployee(
      {
        employeeIds: employee_id,
      },
      {
        onSuccess: (res) => {
          handleClose();
          setGrantInactiveUser([]);
        },
        onError: (err) => {
          console.log('err', err);
        },
      }
    );
  };

  const handleRemoveEmloyee = (index) => {
    if (index > -1) {
      grantInactiveUser.splice(index, 1);
      setGrantInactiveUser([...grantInactiveUser]);
    }
  };
  const handlePdf = () => {
    window.print();
  };

  return (
    <div>
      <Dialog open={open} fullWidth maxWidth="lg">
        <DialogTitle className="font-semibold" color="primary">
          <Grid container xs={12} className="flex justify-between">
            <Grid item className="font-semibold leading-[150%] text-[26px]">
              {changeModal === '1' ? 'Please Confirm' : 'Employee Data'}
            </Grid>
            <Grid item>
              <Grid container className="flex justify-between gap-4">
                <Grid item>
                  {changeModal === '1' ? (
                    <Button
                      variant="standard"
                      startIcon={<MdDeleteForever />}
                      onClick={handleRevokeAccess}
                      className="leading-[150%] w-[277px] h-[51px] capitalize font-semibold text-[18px] rounded-lg  py-2 text-white bg-gradient-to-b from-[#659ECD] to-[#1F305F]"
                    >
                      Remove Access
                    </Button>
                  ) : (
                    <Button
                      variant="standard"
                      startIcon={
                        <FileDownloadOutlinedIcon
                          sx={{
                            width: '24px',
                            height: '24px',
                          }}
                        />
                      }
                      onClick={handlePdf}
                      className="leading-[150%] w-[277px] h-[51px] capitalize font-semibold text-[18px] rounded-lg px-14 py-2 text-white bg-gradient-to-b from-[#659ECD] to-[#1F305F]"
                    >
                      Download
                    </Button>
                  )}
                </Grid>
                {changeModal === '1' ? (
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={handleClose}
                      className="leading-[150%] font-semibold text-[18px] px-8  capitalize shadow-md rounded-lg w-[149px] h-[51px] text-black"
                    >
                      Cancel
                    </Button>
                  </Grid>
                ) : (
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={handleClose}
                      className="leading-[150%] font-semibold text-[18px] px-8  capitalize shadow-md rounded-lg w-[149px] h-[51px] text-black"
                    >
                      Done
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider
          variant="middle"
          sx={{
            bgcolor: '#333B7A',
          }}
        />
        <DialogContent>
          {grantInactiveUser?.map((revokeUserData, index) => {
            return (
              <>
                <div className="flex  justify-end my-2 ">
                  <IconButton
                    aria-label="close"
                    onClick={() => handleRemoveEmloyee(index)}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
                <Grid conatiner className="flex flex-row p-4 py-4">
                  <Grid
                    conatiner
                    className="flex justify-between gap-[48px] mx-[12px]"
                  >
                    <Grid item className="w-[264px] h-[263px] bg-[#D9D9D9]">
                      <img
                        src={revokeUserData?.original?.avatar || DefaultImage}
                        alt=""
                      />
                    </Grid>
                    {revokeUserData?.original?.qrToken && (
                      <Grid item className="w-[264px] h-[263px] ">
                        <QRCode
                          style={{
                            height: 'auto',
                            maxWidth: '100%',
                            width: '100%',
                          }}
                          value={revokeUserData?.original?.qrToken || ''}
                        />
                      </Grid>
                    )}
                  </Grid>

                  <Grid conatiner className="mx-[48px]">
                    <Grid
                      conatiner
                      className="flex justify-between  gap-[72px]"
                    >
                      <Grid
                        item
                        className="font-[700] text-[22px] leading-[150%]"
                      >
                        Employee Id
                      </Grid>
                      <Grid item>
                        <CustomTextField
                          variant="standard"
                          type="text"
                           readOnly
                          value={revokeUserData?.original?.empCode}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      conatiner
                      className="flex justify-between my-[16px] gap-[72px] w-[100%]"
                    >
                      <Grid
                        item
                        className="font-[700] text-[22px] leading-[150%]"
                      >
                        Name
                      </Grid>

                      <Grid item className="!w-[200%] ml-[76px]">
                        <CustomTextField
                          variant="standard"
                          type="text"
                          //  readOnly
                          multiline
                          maxRows={10}
                          value={revokeUserData?.original?.name}
                          readOnly
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      conatiner
                      className="flex justify-between my-[16px] gap-[72px]"
                    >
                      <Grid
                        item
                        className="font-[700] text-[22px] leading-[150%]"
                      >
                        Factory code
                      </Grid>
                      <Grid item>
                        <CustomTextField
                          variant="standard"
                          type="text"
                           readOnly
                          value={revokeUserData?.original?.factory}
                        />
                      </Grid>
                    </Grid>
                    {/* <Grid
                      conatiner
                      className="flex justify-between my-[16px] gap-[72px]"
                    >
                      <Grid
                        item
                        className="font-[700] text-[22px] leading-[150%]"
                      >
                        City
                      </Grid>
                      <Grid item>
                        <CustomTextField
                          variant="standard"
                          type="text"
                           readOnly
                          value={revokeUserData?.original?.city}
                        />
                      </Grid>
                    </Grid> */}

                    <Grid
                      conatiner
                      className="flex justify-between my-[16px] gap-[72px]"
                    >
                      <Grid
                        item
                        className="font-[700] text-[22px] leading-[150%]"
                      >
                        User name
                      </Grid>
                      <Grid item>
                        <CustomTextField
                          variant="standard"
                          type="text"
                           readOnly
                          value={revokeUserData?.original?.username}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      conatiner
                      className="flex justify-between my-[16px] gap-[72px]"
                    >
                      <Grid
                        item
                        className="font-[700] text-[22px] leading-[150%]"
                      >
                        Password
                      </Grid>

                      <Grid item>
                        <CustomTextField
                          variant="standard"
                          type="text"
                           readOnly
                          value={revokeUserData?.original?.password}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      conatiner
                      className="flex justify-between my-[16px] gap-[72px]"
                    >
                      <Grid
                        item
                        className="font-[500] text-[16px] leading-[150%] text-red-500"
                      >
                        *Helpline contact: +91 9008244827
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <div className="mb-3">
                  <Divider
                    variant="middle"
                    sx={{
                      bgcolor: '#333B7A',
                    }}
                  />
                </div>
              </>
            );
          })}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default RemoveAccessModal;
