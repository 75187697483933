import { useState } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { Search as SearchIcon, Clear as ClearIcon } from '@mui/icons-material';

const TableSearch = ({ onChange, placeholder = '' }) => {
  const clearSearch = () => {
    onChange('');
    setSearchText('');
  };
  const [searchText, setSearchText] = useState('');
  const handleSearchChange = (search) => {
    setSearchText(search);
    onChange(search);
  };

  return (
    <TextField
      placeholder={`${placeholder}`}
      value={searchText}
      autoComplete="off"
      onChange={(e) => handleSearchChange(e.target.value)}
      variant="standard"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon style={{ color: 'white' }} />
          </InputAdornment>
        ),

        ...(searchText && {
          endAdornment: (
            <InputAdornment position="end" className="flex items-center">
              <ClearIcon
                onClick={clearSearch}
                className="cursor-pointer text-white"
              />
            </InputAdornment>
          ),
        }),
      }}
      sx={{
        marginTop: '20px',

        '& .MuiInputBase-root': {
          backgroundColor: '#333B7A',
          color: 'white',
        },
        '& .MuiInput-underline': {
          borderColor: 'white',
          borderBottom: '1px solid white',
        },
      }}
    />
  );
};

export default TableSearch;
