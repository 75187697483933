import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useToast } from 'utils';
import { axios } from 'utils';

const GetPaymentGateway = () => {
    return axios({
      method: 'GET',
      url: `/api/v1/admin/payment/gateway`,
    });
  };
  
  export const useGetPaymentGatewayData = () => {
    return useQuery( ['PAYMENTGATEWAY'],
    () => GetPaymentGateway(),);
  };

  const GetActivePaymentGateway = () => {
    return axios({
      method: 'GET',
      url: `/api/v1/admin/payment/gateway/active`,
    });
  };
  
  export const useGetActivePaymentGateway = () => {
    return useQuery( ['PAYMENTGATEWAYACTIVE'],
    () => GetActivePaymentGateway(),);
  };

  const changePaymentGateway = (data) => {
    const method = 'PATCH';
    let url = `/api/v1/admin/payment/gateway/active`
    return axios({ method, url, data });
  };
  
  export const GrantChangePaymentGateway = () => {
    const queryClient = useQueryClient();
    const responseToast = useToast();
  
    return useMutation(changePaymentGateway, {
      onSuccess: (response) => {
        console.log(response, 'response')
        queryClient.invalidateQueries(['PAYMENTGATEWAYACTIVE']);
        responseToast.toastSuccess(
        response?.data || 'Changing in progress! Services will be continue after 5 minutes!'
      );
      },
   
    });
  };


