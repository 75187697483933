import React, { useEffect, useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  Box,
  Container,
  Typography,
} from '@mui/material';
import {
  GrantChangePaymentGateway,
  useGetActivePaymentGateway,
  useGetPaymentGatewayData,
} from './PaymentQueries';

const PaymentGateway = () => {
  const { data: paymentMethodActive, status: activeStatus } =
    useGetActivePaymentGateway();
  const { data: paymentMethods, status: methodsStatus } =
    useGetPaymentGatewayData();
   const { mutate: grantChangeRequest} = GrantChangePaymentGateway();

  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    if (paymentMethodActive?.data) {
      setSelectedValue(paymentMethodActive?.data);
    }
  }, [paymentMethodActive]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    grantChangeRequest(
      {
        "gateway": selectedValue,
      }
    );
  };
  // Only render the form when both the data are successfully fetched
  //  if (activeStatus !== 'success' || methodsStatus !== 'success') {
  //   return <div>Loading...</div>;
  // }

  return (
    <Container>
      <div className="my-8 p-8 bg-white rounded-xl shadow-md max-w-[400px]">
        <Typography
          variant="h5"
          className="text-center text-2xl font-bold mb-6"
        >
          Change Payment Gateway
        </Typography>
        <form onSubmit={handleSubmit} className="space-y-4">
          <FormControl component="fieldset" className="w-full">
            <RadioGroup
              aria-label="payment-options"
              name="payment-options"
              value={selectedValue}
              onChange={handleChange}
              className="flex flex-col space-y-2"
            >
              {paymentMethods?.data?.map((method, index) => (
                <FormControlLabel
                  key={index}
                  value={method}
                  control={<Radio color="primary" />}
                  label={method}
                  className="flex items-center space-x-2"
                />
              ))}
            </RadioGroup>
          </FormControl>
          <Box mt={2}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="w-full py-2 text-lg"
              sx={{
                '&:hover': {
                  backgroundColor: 'primary.main',
                },
              }}
            >
              Submit
            </Button>
          </Box>
        </form>
      </div>
    </Container>
  );
};

export default PaymentGateway;
