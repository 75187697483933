import React, { useMemo } from 'react';
import CompanyLogo from 'assets/Images/CompanyLogo.png';
import HomeLogo from 'assets/Icons/HomeLogo.svg';
import LogOutLogo from 'assets/Icons/LogOutLogo.svg';
import { getLocalStorageItem } from 'hooks';
import { useLocation, useNavigate } from 'react-router';
import { Button, Typography } from '@mui/material';
import { pageDetails, routeKey, routes } from 'routes/RouteConstants';
import SettingsIcon from '@mui/icons-material/Settings';
const TopNav = () => {
  const authToken = getLocalStorageItem('AUTH_TOKEN');
  const location = useLocation();

  const { pathname } = useLocation();

  const currentPageDetails = useMemo(() => routes[routeKey[pathname]]);

  const navigate = useNavigate();

  const handleClickAdminPortal = () => {
    navigate('/admin-portal');
  };
  const handleClickLogOut = () => {
    localStorage.clear();
    navigate('/login');
  };
  const changePayment = () => {
    navigate('/payment-gateway');
  };
  return (
    <div className="header">
      {Boolean(authToken) && location.pathname !== '/' ? (
        <div className={'flex justify-between p-2 '}>
          <>
            <img
              src={CompanyLogo}
              alt="logo"
              onClick={handleClickAdminPortal}
              className="h-[65px] w-[165px] py-[4px] px-[76px] cursor-pointer"
            />
            <Typography
              fontSize={'28px'}
              fontWeight={500}
              color={'white'}
              lineHeight={'42px'}
              className="text-center my-auto"
            >
              {currentPageDetails?.title}
            </Typography>

            <div className='flex gap-4'>
            <Button
              variant="standard"
              startIcon={
                <SettingsIcon style={{ fontSize: '24px' }}/>
              }
              onClick={changePayment}
              className="capitalize font-semibold  h-[45px] text-[18px] rounded-lg px-4  my-auto mr-[20px] text-white border-white border-solid border-[1px] "
            >
              Setting
            </Button>
            <Button
              variant="standard"
              startIcon={
                <img
                  src={LogOutLogo}
                  alt="LogOut Logo"
                  className="h-[25px] w-[24px]"
                />
              }
              onClick={handleClickLogOut}
              className="capitalize font-semibold  h-[45px] w-[150px] text-[18px] rounded-lg px-10  my-auto mr-[76px] text-white border-white border-solid border-[1px] "
            >
              Logout
            </Button>
            </div>
          </>
        </div>
      ) : (
        <div className="flex items-center justify-center p-2 py-auto">
          <img
            src={CompanyLogo}
            alt="logo"
            className="h-[65px] w-[165px] py-[4px]"
          />
        </div>
      )}
    </div>
  );
};

export default TopNav;
