import React from 'react';
import { Grid, Typography } from '@mui/material';

import ManagementLogo from 'assets/Icons/ManagementLogo.svg';
import DatatLogo from 'assets/Icons/DataLogo.svg';
import { useNavigate } from 'react-router';

const AdminPortal = () => {
  const navigate = useNavigate();

  const handleClickDashBoard = () => {
    navigate('/dashboard');
  };

  const handleClickManagementLogo = () => {
    navigate('/manage-employees');
  };

  return (
    <Grid
      container
      className="flex justify-evenly items-center  m-auto h-full max-w-[1200px] "
    >
      <Grid item className="text-center">
        <div
          className="bg-[#ECF6FF] p-5 rounded-lg cursor-pointer shadow-md"
          onClick={handleClickDashBoard}
        >
          <img src={DatatLogo} alt="Data Logo" />
        </div>

        <Typography
          className="text-[#333B7A]  font-bold py-[36px]"
          variant="h4"
        >
          Data Dashboard
        </Typography>
      </Grid>
      <Grid item className="text-center">
        <div
          className="bg-[#ECF6FF] rounded-lg p-5 w-[221px] h-[221px] cursor-pointer shadow-md "
          onClick={handleClickManagementLogo}
        >
          <img src={ManagementLogo} alt="Management Logo" />
        </div>

        <Typography
          className="text-[#333B7A]  font-bold text-center py-[36px]"
          variant="h4"
        >
          Manage Employees
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AdminPortal;
