import React, { useMemo, useState } from 'react';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
} from '@mui/material';
import Papa from 'papaparse';

import TabPanel from './TabPanel';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import DatePicker from 'react-datepicker';
import { AiFillWallet } from 'react-icons/ai';
import { TbArrowsLeftRight } from 'react-icons/tb';
import ChangeHistoryRoundedIcon from '@mui/icons-material/ChangeHistoryRounded';
import RefreshLogo from 'assets/Icons/refreshLogo.svg';
import DataTableCustom from 'components/DataTableCustom';
import 'react-datepicker/dist/react-datepicker.css';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  useGetAllEmployeeQuery,
  useGetAllTransactionsQuery,
} from './DashboardQueries';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import TextField from '@mui/material/TextField';
import './DateRangePicker.css';
import * as XLSX from 'xlsx';

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { FaCalendar } from 'react-icons/fa';
import { format } from 'date-fns';
import { useNavigate } from 'react-router';
import RouteConstants from 'routes/RouteConstants';

const CustomInput = ({ value, onClick }) => (
  <div className="date-picker-input" onClick={onClick}>
    <span className="date-picker-icon">
      <TextField
        sx={{
          '& .MuiOutlinedInput-root': {
            height: '40px',
            borderRadius: '10px',
          },
        }}
        placeholder="Select Date Range"
        type="text"
        className="rounded-[10px]"
        value={value}
        autoComplete="off"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FaCalendar />
            </InputAdornment>
          ),
        }}
      />
    </span>
  </div>
);
const Dashboard = () => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState('1');

  const ITEM_HEIGHT = 58;
  const ITEM_PADDING_TOP = 4;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  const filters = [
    'Transaction Status',
    'Factory Code',
    'Reason',
    'Amount Range',
  ];

  const TransactionStatusFilter = [
    {
      id: '1',
      label: 'Success',
      value: 'SUCCESS',
    },
    {
      id: '2',
      label: 'Error',
      value: 'ERROR',
    },
    {
      id: '3',
      label: 'Failed',
      value: 'FAILED',
    },
    {
      id: '4',
      label: 'Pending',
      value: 'PENDING',
    },
    {
      id: '5',
      label: 'Reversed',
      value: 'REVERSED',
    },
    {
      id: '6',
      label: 'Rejected',
      value: 'REJECTED',
    },
  ];

  const ReasonsFilter = [
    {
      id: '1',
      label: 'Festival',
      value: 'Festival',
    },
    {
      id: '2',
      label: 'Medical',
      value: 'Medical',
    },
    {
      id: '3',
      label: 'Lending',
      value: 'Lending',
    },
    {
      id: '4',
      label: 'Children',
      value: 'Children',
    },
    {
      id: '5',
      label: 'Food',
      value: 'Food',
    },
    {
      id: '6',
      label: 'Repay loans',
      value: 'Repay loans',
    },
    {
      id: '7',
      label: 'Money to family',
      value: 'Money to family',
    },
    {
      id: '8',
      label: 'Others',
      value: 'Others',
    },
  ];
  const employeefilters = ['Amount Range', 'Factory Code', 'Designation'];
  const [dashboardFilters, setDashboardFilters] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDateRange, endDateRange] = dateRange;

  const [selectedFilters, setSelectedFilters] = useState({
    startDate: null,
    endDate: null,
    empCode: '',
    designation: '',
    txnStatus: '',
    factory: '',
    reason: '',
    name: '',
    maxAmount: '',
    minAmount: '',
  });
  const [selectedTransactionFilters, setSelectedTransactionFilters] = useState(
    {}
  );
  const [selectedEmployeeFilters, setSelectedEmployeeFilters] = useState({});
  const [
    transactionInformationListFilters,
    setTransactionInformationListFilters,
  ] = useState({});
  const [employeeInformationListFilters, setEmployeeInformationListFilters] =
    useState({});

  const handleChange = (event) => {
    const value = event.target.value;
    setDashboardFilters((state) =>
      state?.includes(value)
        ? state?.filter((opt) => opt !== value)
        : [...state, value]
    );
  };

  const handleCsvExcel = (event) => {
    const value = event.target.value;
    if (value === 'Download CSV' && tabValue === '1') {
      // Download the CSV file
      const csvData = handleTransactionsDownload();
      const csvBlob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const csvUrl = URL.createObjectURL(csvBlob);
      const link = document.createElement('a');
      link.href = csvUrl;
      link.setAttribute('download', 'transactionsList.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (value === 'Download CSV' && tabValue === '2') {
      // Download the CSV file
      const csvData = handleEmployeeDownload();
      const csvBlob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const csvUrl = URL.createObjectURL(csvBlob);
      const link = document.createElement('a');
      link.href = csvUrl;
      link.setAttribute('download', 'employeeList.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (value === 'Excel document' && tabValue === '1') {
      // Download the Excel file
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(transactionsList);
      XLSX.utils.book_append_sheet(wb, ws, 'Transactions');
      XLSX.writeFile(wb, 'transactionsList.xlsx');
    } else if (value === 'Excel document' && tabValue === '2') {
      // Download the Excel file
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(employeeList);
      XLSX.utils.book_append_sheet(wb, ws, 'Employee');
      XLSX.writeFile(wb, 'employeeList.xlsx');
    }
  };

  const handleFactoryFilter = (event) => {
    setSelectedFilters({
      ...selectedFilters,
      factory: event.target.value,
    });
  };
  const handleMaxFilter = (event) => {
    setSelectedFilters({
      ...selectedFilters,

      maxAmount: event.target.value,
    });
  };
  const handleStartEndDateFilter = (update) => {
    setSelectedFilters({
      ...selectedFilters,
      startDate: update?.[0],
      endDate: update?.[1],
    });
  };

  const handleMinFilter = (event) => {
    setSelectedFilters({
      ...selectedFilters,
      minAmount: event.target.value,
    });
  };

  const handleReasonFilter = (event) => {
    setSelectedFilters({
      ...selectedFilters,
      reason: event.target.value,
    });
  };

  const handleDesignationFilter = (event) => {
    setSelectedFilters({
      ...selectedFilters,
      designation: event.target.value,
    });
  };

  const handleTransactionStatusFilter = (event) => {
    setSelectedFilters({
      ...selectedFilters,
      txnStatus: event.target.value,
    });
  };
  const handleTransactionFilter = () => {
    setSelectedTransactionFilters({
      ...selectedFilters,
      startDate:
        selectedFilters?.startDate &&
        format(new Date(selectedFilters?.startDate), 'yyyy-MM-dd'),
      endDate:
        selectedFilters?.endDate &&
        format(new Date(selectedFilters?.endDate), 'yyyy-MM-dd'),
    });
  };
  const handleEmployeeFilter = () => {
    setSelectedEmployeeFilters({
      ...selectedFilters,
      startDate:
        selectedFilters?.startDate &&
        format(new Date(selectedFilters?.startDate), 'yyyy-MM-dd'),
      endDate:
        selectedFilters?.endDate &&
        format(new Date(selectedFilters?.endDate), 'yyyy-MM-dd'),
    });
  };

  // queries
  const { data: transactionsData, dataUpdatedAt } = useGetAllTransactionsQuery({
    transactionInformationListFilters,
    selectedTransactionFilters,
  });
  const { data: employeeData, dataUpdatedAt: employeeUpdatedAt } =
    useGetAllEmployeeQuery({
      employeeInformationListFilters,
      selectedEmployeeFilters,
    });

  const { employeeList, paginationInformationEmployee } = useMemo(() => {
    if (employeeUpdatedAt) {
      return {
        employeeList: employeeData?.data?.results,
        paginationInformationEmployee: employeeData?.data?.options,
      };
    } else {
      return { employeeList: [], paginationInformationEmployee: {} };
    }
  }, [employeeUpdatedAt]);

  const { transactionsList, paginationTransaction } = useMemo(() => {
    if (dataUpdatedAt) {
      return {
        transactionsList: transactionsData?.data?.results,
        paginationTransaction: transactionsData?.data?.options,
      };
    } else {
      return { transactionsList: [], paginationTransaction: {} };
    }
  }, [dataUpdatedAt]);


  const handleTransactionsDownload = () => {
    const csvData = Papa.unparse([
      [
        'Employee Id',
        'UTR',
        'Name',
        'Account No.',
        'Date & Time',
        'Amount',
        'Available Amount',
      ],
      ...transactionsList?.map((employee) => [
        employee?.empCode,
        employee?.utr,
        employee?.name,
        employee?.accountNumber,
        format(new Date(employee?.txnDate), "dd/MM/yyyy 'at' hh:mm a"),
        employee.amount,
        employee?.lastWithdrawableAmount,
      ]),
    ]);

    return csvData;
  };
  const handleEmployeeDownload = () => {
    const csvData = Papa.unparse([
      [
        'Employee Id',
        'Name',
        'Sessions',
        'Successful Transactions',
        'Unsuccessful Transactions',
        'Total Amount',
      ],
      ...employeeList?.map((employee) => [
        employee?.empCode,
        employee?.name,
        employee?.totalSessions,
        employee?.unsuccessfulTransactions,
        employee?.successfulTransactions,
        employee?.totalAmount,
      ]),
    ]);

    return csvData;
  };

  const transactionsDownload = ['Download CSV', 'Excel document'];
  // const employeeDownload = [
  //   <CSVLink
  //     data={employeeList}
  //     className="text-[#333B7A] text-[17px] leading-[27px] text-left no-underline font-semibold"
  //   >
  //     CSV file1
  //   </CSVLink>,
  //   'Excel document',
  // ];

  const columns = useMemo(
    () => [
      {
        Header: 'Transaction Id',
        accessor: 'txnId',
      },

      {
        Header: 'empcode',
        accessor: 'empCode',
      },
      {
        Header: 'UTR',
        accessor: 'utr',
      },
      { Header: 'Factory code', accessor: 'factory', disableSortBy: true },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Account No.',
        accessor: 'accountNumber',
      },

      {
        Header: 'Date & Time',
        accessor: 'txnDate',
        Cell: ({ row: { original } }) => {
          return (
            <div className="flex ">
              <p>
                {format(new Date(original?.txnDate), "dd/MM/yyyy 'at' hh:mm a")}
              </p>
            </div>
          );
        },
      },
      {
        Header: 'Amount',
        accessor: 'amount',

        Cell: ({ row: { original } }) => {
          return (
            <div className="flex ">
              <p>&#8377;</p>
              <p>{original?.amount}</p>
            </div>
          );
        },
      },

      {
        Header: 'Available Amount',
        accessor: 'lastWithdrawableAmount',
        Cell: ({ row: { original } }) => {
          return (
            <div className="flex ">
              {original?.lastWithdrawableAmount ? (
                <>
                  <p>&#8377;</p>
                  <p>{original?.lastWithdrawableAmount}</p>
                </>
              ) : (
                <p>-</p>
              )}
            </div>
          );
        },
      },

      {
        Header: 'Status',
        accessor: 'statusDisplayName',
        Cell: ({ row: { original } }) => {
          return (
            <div className="flex gap-1">
              <p>{original?.statusDisplayName}</p>
              <p className="">
                <Tooltip title={original?.msg}>
                  <InfoOutlinedIcon fontSize="2px" />
                </Tooltip>
              </p>
            </div>
          );
        },
      },
      {
        Header: 'Reason',
        accessor: 'purpose',
      },
    ],
    []
  );

  const columns1 = useMemo(
    () => [
      {
        Header: 'empcode',
        accessor: 'empCode',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      { Header: 'Factory code', accessor: 'factory' },
      {
        Header: 'Designation',
        accessor: 'designation',
      },
      {
        Header: 'Sessions',
        accessor: 'totalSessions',
      },
      {
        Header: 'Successful Transactions',
        accessor: 'successfulTransactions',
      },
      {
        Header: 'Unsuccessful Transactions',
        accessor: 'unsuccessfulTransactions',
      },

      {
        Header: 'Total Amount',
        accessor: 'totalAmount',
      },
    ],
    []
  );
  return (
    <div className="pl-[94px] pr-[95px]">
      <div className="mt-[37px] flex justify-between">
        <div>
          <TabPanel
            tabvalue={tabValue}
            setTabValue={setTabValue}
            setDashboardFilters={setDashboardFilters}
            setSelectedFilters={setSelectedFilters}
            setDateRange={setDateRange}
            setTransactionInformationListFilters={
              setTransactionInformationListFilters
            }
          />
        </div>

        <div className="flex items-center gap-2">
          <Button
            className="text-white text-[17px] bg-gradient-to-b from-[#659ECD] to-[#1F305F] leading-[27px] text-left font-semibold rounded-[8px]  h-[48px] border-none -mt-2 "
            sx={{
              textTransform: 'initial',
            }}
            onClick={() => navigate(RouteConstants.ATTENDANCE)}
          >
            Show Attendance
          </Button>
          <Grid item>
            <FormControl sx={{ m: 1, width: 248 }}>
              <Select
                value={'Download'}
                onChange={handleCsvExcel}
                className="text-white text-[17px] bg-gradient-to-b from-[#659ECD] to-[#1F305F] leading-[27px] text-left font-semibold rounded-[8px]  h-[48px] border-none"
                input={
                  <OutlinedInput className="text-[20px] -mt-2  font-semibold text-center text-white " />
                }
                startAdornment={
                  <InputAdornment position="start">
                    <FileDownloadOutlinedIcon className="text-white" />
                  </InputAdornment>
                }
                sx={{
                  '& .MuiSelect-icon': {
                    color: 'white',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                }}
                renderValue={(selected) => selected}
              >
                {transactionsDownload?.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    className="text-[20px] leading-[27px] text-[#333B7A] font-semibold hover:bg-[#333B7A] hover:text-white"
                  >
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </div>
      </div>
      <Grid
        container
        className="bg-[#ECF6FF] flex justify-between  p-4 rounded-lg  shadow-md"
      >
        <div className="flex gap-2 justify-between items-center">
          <Grid item style={{ zIndex: 9999 }}>
            <DatePicker
              selectsRange={true}
              startDate={startDateRange}
              endDate={endDateRange}
              onChange={(update) => {
                setDateRange(update);
                handleStartEndDateFilter(update);
              }}
              maxDate={new Date()}
              customInput={<CustomInput />}
              className="react-datepicker__wrapper"
            />
          </Grid>

          {dashboardFilters?.includes('Transaction Status') && (
            <Grid item>
              <FormControl>
                <InputLabel id="my-select-placeholder">
                  Transaction Status
                </InputLabel>
                <Select
                  labelId="my-select-placeholder"
                  id="demo-simple-select"
                  label="Transaction Status"
                  value={selectedFilters?.txnStatus}
                  onChange={handleTransactionStatusFilter}
                  helperText="Transaction Status"
                  className="min-w-[160px]   h-[40px] rounded-[10px]"
                  startAdornment={
                    <InputAdornment position="start">
                      <TbArrowsLeftRight className="rounded-lg text-black" />
                    </InputAdornment>
                  }
                >
                  {TransactionStatusFilter.map((name) => (
                    <MenuItem key={name?.label} value={name?.value}>
                      <ListItemText primary={name?.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {tabValue === '2' && dashboardFilters?.includes('Designation') && (
            <Grid item>
              <TextField
                sx={{
                  '& .MuiOutlinedInput-root': {
                    height: '40px',
                    borderRadius: '10px',
                  },
                }}
                value={selectedFilters?.designation}
                placeholder="Designation"
                type="text"
                onInput={(e) => {
                  e.target.value = e.target.value.toUpperCase();
                }}
                onChange={handleDesignationFilter}
                className="rounded-lg max-w-[170px] "
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <BusinessCenterOutlinedIcon className="text-black" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}

          {dashboardFilters?.includes('Factory Code') && (
            <Grid item>
              <TextField
                sx={{
                  '& .MuiOutlinedInput-root': {
                    height: '40px',
                    borderRadius: '10px',
                  },
                }}
                value={selectedFilters?.factory}
                placeholder="Factory code"
                type="text"
                onChange={handleFactoryFilter}
                className="rounded-lg max-w-[170px] min-w-[150px]"
                onInput={(e) => {
                  e.target.value = e.target.value.toUpperCase();
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOnOutlinedIcon className="text-black" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}

          {dashboardFilters?.includes('Reason') && (
            <Grid item>
              <FormControl>
                <InputLabel id="demo-simple-select-label">Reason</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={handleReasonFilter}
                  value={selectedFilters?.reason}
                  label="Reason"
                  className="min-w-[170px] h-[40px] rounded-[10px] min-w-[100px"
                  startAdornment={
                    <InputAdornment position="start">
                      <ChangeHistoryRoundedIcon className="rounded-lg text-black" />
                    </InputAdornment>
                  }
                >
                  {ReasonsFilter?.map((name) => (
                    <MenuItem key={name?.label} value={name?.label}>
                      <ListItemText primary={name?.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          {dashboardFilters?.includes('Amount Range') && (
            <Grid
              item
              container
              className="flex  flex-row items-center max-w-[180px]  border-[1px] border-[#333B7A] border-solid h-[40px] rounded-lg"
            >
              <Grid item xs={1}></Grid>
              <Grid item xs={4}>
                <TextField
                  variant="standard"
                  value={selectedFilters?.minAmount}
                  placeholder="Min"
                  type="number"
                  className=""
                  onChange={handleMinFilter}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <AiFillWallet className="text-black w-[20px] h-[20px] " />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={5}>
                <TextField
                  variant="standard"
                  value={selectedFilters?.maxAmount}
                  placeholder="Max"
                  type="number"
                  className=""
                  onChange={handleMaxFilter}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <ArrowRightAltIcon className="text-black w-[20px] h-[20px]" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          )}

          <Grid item className="rounded-lg">
            <Button
              variant="outlined"
              sx={{ height: '39px', borderRadius: '8px' }}
              onClick={() => {
                setSelectedFilters({
                  startDate: null,
                  endDate: null,
                  empCode: '',
                  txnStatus: '',
                  factory: '',
                  reason: '',
                  maxAmount: '',
                  minAmount: '',
                });
                setDateRange([null, null]);
                setDashboardFilters([]);
                setSelectedTransactionFilters({});
                setSelectedEmployeeFilters({});
              }}
            >
              <img
                src={RefreshLogo}
                alt="Refresh Logo"
                className="w-[24px] h-[24px] p-1"
              />
            </Button>
          </Grid>
        </div>
        <div>
          <Grid item container className="gap-1">
            {tabValue === '1' ? (
              <Grid item>
                <FormControl sx={{ m: 0.3 }}>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    value={'+Add Filter'}
                    disableCloseOnSelect
                    onChange={handleChange}
                    input={
                      <OutlinedInput className="leading-[150%] capitalize font-semibold text-[12px] h-[37px]  text-[#272727]" />
                    }
                    renderValue={(selected) => selected}
                    MenuProps={MenuProps}
                  >
                    {filters?.map((name) => (
                      <MenuItem key={name} value={name}>
                        <ListItemText primary={name} />
                        <Checkbox
                          checked={dashboardFilters?.indexOf(name) > -1}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            ) : (
              <Grid item>
                <FormControl sx={{ m: 0.3 }}>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    value={'+Add Filter'}
                    onChange={handleChange}
                    input={
                      <OutlinedInput className="leading-[150%] capitalize font-semibold text-[12px] h-[37px]  text-[#272727]" />
                    }
                    renderValue={(selected) => selected}
                    MenuProps={MenuProps}
                  >
                    {employeefilters?.map((name) => (
                      <MenuItem key={name} value={name}>
                        <ListItemText primary={name} />
                        <Checkbox
                          checked={dashboardFilters?.indexOf(name) > -1}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            <Grid item className="pl-5">
              <Button
                variant="standard"
                onClick={
                  tabValue === '1'
                    ? handleTransactionFilter
                    : handleEmployeeFilter
                }
                className="leading-[150%] capitalize font-semibold text-sm px-14 py-2 text-white bg-gradient-to-b from-[#659ECD] to-[#1F305F]"
              >
                Show Data
              </Button>
            </Grid>
          </Grid>
        </div>
      </Grid>

      <div className="my-2 ">
        {tabValue === '1' ? (
          <DataTableCustom
            data={transactionsList}
            columns={columns}
            updateFilters={setTransactionInformationListFilters}
            paginationInformation={paginationTransaction}
            isSearch
          />
        ) : (
          <DataTableCustom
            data={employeeList}
            columns={columns1}
            updateFilters={setEmployeeInformationListFilters}
            paginationInformation={paginationInformationEmployee}
            isSearch
          />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
