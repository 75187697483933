import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  IconButton,
  Divider,
  Button,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { CustomTextField } from 'components';
import QRCode from 'react-qr-code';
import { BsCheck2Circle } from 'react-icons/bs';
import { GrantAccessUserQuery } from './ManageEmployeesQueries';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DefaultImage from 'assets/Images/defaultImage.webp';

const EmployeeDataModal = (props) => {
  const { open, handleClose, grantInactiveUser, setInactiveGrantUser } = props;

  // states
  const [grantEmployee, setGrantEmployee] = useState([]);
  const [commonEmployeeData, setCommonEmployeeData] = useState([]);

  // queries
  const { mutate: grantAccessUser } = GrantAccessUserQuery();

  const handleGrantAccess = () => {
    const employee_id = grantInactiveUser?.map(
      (employee) => employee?.original?.id
    );
    grantAccessUser(
      {
        employeeIds: employee_id,
      },
      {
        onSuccess: (res) => {
          setGrantEmployee(res?.data);
        },
        onError: (err) => {
          console.log('err', err);
        },
      }
    );
  };
  const handlePdf = () => {
    window.print();
  };

  const handleRemoveMe = (index) => {
    if (index > -1) {
      grantInactiveUser.splice(index, 1);
      setInactiveGrantUser([...grantInactiveUser]);
    }
  };

  useEffect(() => {
    if (grantEmployee?.length <= 0) {
      const tempArr = grantInactiveUser?.map((el) => el.original);
      setCommonEmployeeData(tempArr);
    } else {
      setCommonEmployeeData(grantEmployee);
    }
  }, [grantInactiveUser, grantEmployee]);

  useEffect(() => {
    if (open) {
      setGrantEmployee([]);
    }
  }, [open]);

  return (
    <div>
      <Dialog open={open} fullWidth maxWidth="lg">
        <DialogTitle className="font-semibold" color="primary">
          <Grid container xs={12} className="flex justify-between">
            <Grid item className="font-semibold leading-[150%] text-[26px]">
              Employee Data
            </Grid>
            <Grid item>
              <Grid container className="flex justify-between gap-4">
                {!grantEmployee?.length <= 0 ? (
                  <Grid item>
                    <Button
                      variant="standard"
                      onClick={handlePdf}
                      startIcon={
                        <FileDownloadOutlinedIcon
                          sx={{
                            width: '24px',
                            height: '24px',
                          }}
                        />
                      }
                      className="leading-[150%] w-[277px] h-[51px] capitalize font-semibold text-[18px] rounded-lg px-14 py-2 text-white bg-gradient-to-b from-[#659ECD] to-[#1F305F]"
                    >
                      Download
                    </Button>
                  </Grid>
                ) : (
                  <Grid item>
                    <Button
                      variant="standard"
                      startIcon={<BsCheck2Circle />}
                      onClick={handleGrantAccess}
                      className="leading-[150%] w-[277px] h-[51px] capitalize font-semibold text-[18px] rounded-lg px-14 py-2 text-white bg-gradient-to-b from-[#659ECD] to-[#1F305F]"
                    >
                      Grant Access
                    </Button>
                  </Grid>
                )}
                {!grantEmployee?.length <= 0 ? (
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={handleClose}
                      className="leading-[150%] font-semibold text-[18px] px-8  capitalize shadow-md rounded-lg w-[149px] h-[51px] text-black"
                    >
                      Done
                    </Button>
                  </Grid>
                ) : (
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={handleClose}
                      className="leading-[150%] font-semibold text-[18px] px-8  capitalize shadow-md rounded-lg w-[149px] h-[51px] text-black"
                    >
                      Cancel
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider
          variant="middle"
          sx={{
            bgcolor: '#333B7A',
          }}
        />
        <DialogContent>
          {commonEmployeeData?.map((grantUserData, index) => {
            return (
              <>
                {grantEmployee?.length <= 0 && (
                  <div className="flex  justify-end my-2 ">
                    <IconButton
                      aria-label="close"
                      onClick={() => handleRemoveMe(index)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                )}

                <Grid conatiner className="flex flex-row p-4 py-4">
                  <Grid
                    conatiner
                    className="flex justify-between gap-[48px] mx-[12px]"
                  >
                    <Grid item className="w-[264px] h-[263px] bg-[#D9D9D9]">
                      <img src={grantUserData?.avatar || DefaultImage} alt="" />
                    </Grid>
                    {grantUserData?.qrToken && (
                      <Grid item className="w-[264px] h-[263px] ">
                        <QRCode
                          style={{
                            height: 'auto',
                            maxWidth: '100%',
                            width: '100%',
                          }}
                          value={grantUserData?.qrToken || ''}
                        />
                      </Grid>
                    )}
                  </Grid>

                  <Grid conatiner className="mx-[48px]">
                    <Grid
                      conatiner
                      className="flex justify-between  gap-[72px]"
                    >
                      <Grid
                        item
                        className="font-[700] text-[22px] leading-[150%]"
                      >
                        Employee Id
                      </Grid>
                      <Grid item>
                        <CustomTextField
                          variant="standard"
                          type="text"
                          readOnly
                          value={grantUserData?.empCode}
                        />
                      </Grid>
                    </Grid>
                    
                    <Grid
                      conatiner
                      className="flex justify-between my-[16px] gap-[72px]"
                    >
                      <Grid
                        item
                        className="font-[700] text-[22px]"
                      >
                        Name
                      </Grid>
                      <Grid item className='!w-[200%] ml-[76px]'>
                        <CustomTextField
                          variant="standard"
                          type="text"
                          readOnly
                          multiline
                          maxRows={10}
                          value={grantUserData?.name}
                        />
                      </Grid>
                    </Grid>
                   
                   <Grid
                      conatiner
                      className="flex justify-between my-[16px] gap-[72px]"
                    >
                      <Grid
                        item
                        className="font-[700] text-[22px] leading-[150%]"
                      >
                        Factory code
                      </Grid>
                      <Grid item>
                        <CustomTextField
                          variant="standard"
                          type="text"
                          readOnly
                          value={grantUserData?.factory}
                        />
                      </Grid>
                    </Grid>
                
                    <Grid
                      conatiner
                      className="flex justify-between my-[16px] gap-[72px]"
                    >
                      <Grid
                        item
                        className="font-[700] text-[22px] leading-[150%]"
                      >
                        User name
                      </Grid>
                      <Grid item>
                        <CustomTextField
                          variant="standard"
                          type="text"
                          readOnly
                          value={grantUserData?.username}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      conatiner
                      className="flex justify-between my-[16px] gap-[72px]"
                    >
                      <Grid
                        item
                        className="font-[700] text-[22px] leading-[150%]"
                      >
                        Password
                      </Grid>

                      <Grid item>
                        <CustomTextField
                          readOnly
                          variant="standard"
                          type="text"
                          value={grantUserData?.password}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      conatiner
                      className="flex justify-between my-[16px] gap-[72px]"
                    >
                      <Grid
                        item
                        className="font-[500] text-[16px] leading-[150%] text-red-500"
                      >
                        *Helpline contact: +91 9008244827
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <div className="mb-3">
                  <Divider
                    variant="middle"
                    sx={{
                      bgcolor: '#333B7A',
                    }}
                  />
                </div>
              </>
            );
          })}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EmployeeDataModal;
