import Box from '@mui/material/Box';

import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import * as React from 'react';

function ProgressBar(props) {

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '15px'}}>
      <Box sx={{ width: '100%', mr: 1, h: 10 }}>
        <LinearProgress variant="determinate" {...props} color="primary"  sx={{  height: '10px', borderRadius: '5px' }}/>
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          color="gray.700"
          className="font-semibold"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export default ProgressBar;
