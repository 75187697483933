import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import CommonDialogTitle from './CommonDialogTitle';
import Styles from './CommonDialog.module.scss';

/* to use your custom dialog title : useCommonTitle should be false and title should be a JSX.Element
 also check the default props for dialog width and other features(BackdropClick & EscapeKeyDown) */

const CommonDialog = (props) => {
  const {
    'aria-labelledby': ariaLabelledBy,
    'aria-describedby': ariaDescribedBy,
    children,
    disableEscapeKeyDown,
    fullWidth,
    maxWidth,
    onClose,
    title,
    actions,
    open,
    useCommonTitle,
    PaperComponent,
  } = props;

  return (
    <Dialog
      aria-labelledby={ariaLabelledBy}
      aria-describedby={ariaDescribedBy}
      // onClose={onClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      PaperComponent={PaperComponent}
      classes={
        maxWidth === 'md'
          ? {
              paperWidthMd: Styles.paperWidthMd,
            }
          : null
      }
      disableEscapeKeyDown={disableEscapeKeyDown}
    >
      {useCommonTitle ? (
        <CommonDialogTitle title={title} onClose={onClose} />
      ) : (
        <DialogTitle className={Styles.unCommonDialogTitle}>
          {title}
        </DialogTitle>
      )}

      <DialogContent className="hide-scrollbar">{children}</DialogContent>
      {actions && (
        <DialogActions className={Styles.dialogActionsRoot}>
          {actions}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default CommonDialog;

CommonDialog.defaultProps = {
  'aria-labelledby': undefined,
  'aria-describedby': undefined,
  actions: undefined,
  onClose: () => null,
  fullWidth: true,
  maxWidth: 'lg',
  useCommonTitle: true,
  disableEscapeKeyDown: true,
};
