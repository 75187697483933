import { lazy } from 'react';
import AdminPortal from 'pages/AdminPortal';
import Dashboard from 'pages/Dashboard';
import ManageEmployees from 'pages/ManageEmployees';
import Attendance from 'pages/Dashboard/Attendance';
import PrivacyPolicy from 'pages/Privacy';
import PaymentGatway from 'pages/PaymentGatway';
const Login = lazy(() => import('pages/Login/index'));

const RouteConstants = {
  REDIRECTOR: '/',
  LOGIN: '/login',
  ATTENDANCE: '/attendance',
};

export const routes = {
  REDIRECTOR: {
    pathname: '/',
    title: 'Login',
    isPublic: true,
    element: <Login />,
  },
  LOGIN: {
    pathname: '/login',
    title: 'Login',
    isPublic: true,
    element: <Login />,
  },
  PRIVACY: {
    pathname: '/privacy-policy',
    title: 'Privacy Policy',
    isPublic: true,
    element: <PrivacyPolicy />,
  },
  ADMINPORTAL: {
    pathname: '/admin-portal',
    title: 'Admin Portal',
    isPublic: false,
    element: <AdminPortal />,
  },
  DATADASHBOARD: {
    pathname: '/dashboard',
    title: 'Data Dashboard',
    isPublic: false,
    element: <Dashboard />,
  },
  MANAGEEMPLOYEES: {
    pathname: '/manage-employees',
    title: 'Manage Employees',
    isPublic: false,
    element: <ManageEmployees />,
  },
  SHOW_ATTENDANCE: {
    pathname: RouteConstants.ATTENDANCE,
    title: 'Attendance',
    isPublic: false,
    element: <Attendance />,
  },
  PAYMENTGATWAY: {
    pathname: '/payment-gateway',
    title: 'Payment Gateway',
    isPublic: false,
    element: <PaymentGatway />,
  },
};

export const routeKey = Object.assign(
  {},
  ...Object.keys(routes).map((routeKey) => ({
    [routes[routeKey].pathname]: routeKey,
  }))
);

export const pageDetails = routes[routeKey[window.location.pathname]];

export default RouteConstants;
