import { Box, Button, Grid, TextField } from '@mui/material';
import DataTableCustom from 'components/DataTableCustom';
import React, { useMemo, useRef, useState } from 'react';
import { useGetAttendanceData } from './DashboardQueries';

const Attendance = () => {
  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'date',
        disableSortBy: true,
      },
      {
        Header: 'In Time',
        accessor: 'inTime',
        disableSortBy: true,
      },
      {
        Header: 'Out Time',
        accessor: 'outTime',
        disableSortBy: true,
      },
      {
        Header: 'Total Working Minutes',
        accessor: 'totalWorkingMinutes',
        disableSortBy: true,
      },
      {
        Header: 'Payable Minutes',
        accessor: 'payableMinutes',
        disableSortBy: true,
      },
      {
        Header: ' Break',
        accessor: 'break',
        disableSortBy: true,
      },

      {
        Header: 'Bin Window',
        accessor: 'binWindow',
        disableSortBy: true,
      },
      {
        Header: 'Total Bin',
        accessor: 'totalBin',
        disableSortBy: true,
      },
      {
        Header: 'Wages',
        accessor: 'wages',
        disableSortBy: true,
      },
    ],
    []
  );
  const empCodeRef = useRef();
  const [payload, setPayload] = useState({ enabled: false });
  const { data } = useGetAttendanceData(payload);

  const handleShowData = () => {
    const value = empCodeRef?.current?.value;
    if (value?.trim()) {
      setPayload({ code: value, enabled: true });
    }
  };
  return (
    <>
      <Grid
        container
        className="bg-[#ECF6FF] flex justify-between  p-4 rounded-lg  shadow-md"
      >
        <Grid item>
          <TextField
            sx={{
              '& .MuiOutlinedInput-root': {
                height: '40px',
                borderRadius: '10px',
              },
            }}
            placeholder="Employee code"
            type="text"
            onInput={(e) => {
              e.target.value = e.target.value.toUpperCase();
            }}
            className="rounded-lg "
            inputRef={empCodeRef}
          />
        </Grid>
        <Grid item className="pl-5">
          <Button
            variant="standard"
            onClick={handleShowData}
            className="leading-[150%] capitalize font-semibold text-sm px-14 py-2 text-white bg-gradient-to-b from-[#659ECD] to-[#1F305F]"
          >
            Show Data
          </Button>
        </Grid>
      </Grid>
      <Box className="flex items-center justify-end py-2 ">
        <Box sx={{ borderRight: '1px solid black' }} className="flex gap-4 p-4">
          <p className="font-semibold">Name : </p>{' '}
          <p> {data?.data?.name ?? 'N/A'} </p>
        </Box>
        <Box sx={{ borderRight: '1px solid black' }} className="flex gap-4 p-4">
          <p className="font-semibold">Department : </p>{' '}
          <p> {data?.data?.department ?? 'N/A'} </p>
        </Box>
        <Box sx={{ borderRight: '1px solid black' }} className="flex gap-4 p-4">
          <p className="font-semibold">Designation : </p>{' '}
          <p> {data?.data?.designation ?? 'N/A'} </p>
        </Box>
        <Box sx={{ borderRight: '1px solid black' }} className="flex gap-4 p-4">
          <p className="font-semibold">Salary Balance : </p>{' '}
          <p> {data?.data?.salaryBalance ?? 0} </p>
        </Box>
        <Box sx={{ borderRight: '1px solid black' }} className="flex gap-4 p-4">
          <p className="font-semibold">Total Earned Salary : </p>{' '}
          <p> {data?.data?.totalEarnedSalary ?? 0} </p>
        </Box>
        <Box sx={{ borderRight: '1px solid black' }} className="flex gap-4 p-4">
          <p className="font-semibold">Payable Minutes : </p>{' '}
          <p> {data?.data?.payableMinutes ?? 0} </p>
        </Box>
        <Box className="flex gap-4 p-4">
          <p className="font-semibold">Total Withdrew : </p>{' '}
          <p> {data?.data?.totalWithdrew ?? 0} </p>
        </Box>
        <Box sx={{ borderLeft: '1px solid black' }} className="flex gap-4 p-4">
          <p className="font-semibold">Wages Per Bin : </p>{' '}
          <p> {data?.data?.wagesPerBin ?? 0} </p>
        </Box>
      </Box>
      <DataTableCustom
        data={data?.data?.workings ?? []}
        columns={columns}
        updateFilters={() => undefined}
        // paginationInformation={paginationTransaction}
        isSearch
        isPagination={false}
      />
    </>
  );
};

export default Attendance;
