import React from 'react';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList } from '@mui/lab';

const TabPanel = (props) => {
  const {
    tabvalue,
    setTabValue,
    setDashboardFilters,
    setSelectedFilters,
    setTransactionInformationListFilters,
    setDateRange,
  } = props;

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setDashboardFilters([]);
    setTransactionInformationListFilters({
      page: 1,
      perPage: 25,
      search: '',

      order: 'DESC',
      handlePagination: true,
    });
    setSelectedFilters({
      empCode: '',
      designation: '',
      sessions: '',
      txnStatus: '',
      factory: '',
      reason: '',
      name: '',
      Amount: '',
    });
    setDateRange([null, null]);
  };

  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={tabvalue}>
          <Box>
            <TabList
              aria-label="lab API tabs example"
              onChange={handleTabChange}
              sx={{
                '& .MuiTabs-indicator': {
                  display: 'none',
                },
              }}
            >
              <Tab
                label={
                  <div
                    className={`p-4 rounded-md  text-sm  font-bold ${
                      tabvalue === '1'
                        ? 'bg-[#ECF6FF] text-[#333B7A] opacity-100'
                        : 'text-[#333B7A] bg-white opacity-70'
                    } shadow-2xl  font-bold capitalize text-[24px] flex  gap-2 items-center justify-between`}
                  >
                    Transaction details
                  </div>
                }
                value="1"
                className="shadow-2xl p-0"
              />
              <Tab
                label={
                  <div
                    className={`p-4 rounded-md  text-sm  font-bold ${
                      tabvalue === '2'
                        ? 'bg-[#ECF6FF] text-[#333B7A] opacity-100'
                        : 'text-[#333B7A] bg-white opacity-70'
                    } shadow-2xl  font-bold capitalize text-[24px]  flex  gap-2 items-center justify-between`}
                    onChange={handleTabChange}
                  >
                    Employee data
                  </div>
                }
                value="2"
                className="shadow-2xl p-0"
              />
            </TabList>
          </Box>

          {/* React Table */}
        </TabContext>
      </Box>
    </>
  );
};

export default TabPanel;
