import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { forEach } from 'lodash';

const returnListOfErrors = (errors) => {
  return (
    <>
      {errors.length > 1 ? (
        <>
          <ul>
            {errors.map((errorMessage, errorIndex) => (
              <li className="ml-2">
                &ensp;
                {errorIndex + 1}
                {')'}&nbsp;{errorMessage}
              </li>
            ))}
          </ul>
        </>
      ) : (
        <>{errors[0]}</>
      )}
    </>
  );
};

const commonToastConfig = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  theme: 'colored',
};

const useToast = () => {
  const toastSuccess = (message) => {
    if (message) toast.success(message, commonToastConfig);
  };

  const toastError = (message, error = null) => {
    if (error && Object.keys(error).length !== 0) {
      let errors = [];
      forEach(error, (value, key) => {
        errors = [...errors, ...value];
      });
      toast.error(returnListOfErrors(errors), commonToastConfig);
    } else {
      toast.error(message, commonToastConfig);
    }
  };

  const toastInfo = (message) => {
    if (message) toast.info(message, commonToastConfig);
  };

  return {
    toastSuccess,
    toastInfo,
    toastError,
  };
};

export default useToast;
