import { useQuery } from '@tanstack/react-query';
import { axios } from 'utils';

export const appendQueryParams = (url, data) => {
  let resp = url + '?';
  if (data)
    Object.keys(data).forEach((key) => {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(data[key])) {
        resp += `${key}=${
          typeof data[key] === 'string'
            ? data[key]?.replaceAll('&', '%26')
            : data[key]
        }&`;
      }
    });
  return resp;
};

const FetchTransactionsData = (data) => {
  const method = 'GET';
  let url = appendQueryParams(`api/v1/admin/data-dashboard/transactions`, {
    ...data?.selectedTransactionFilters,
    ...data?.transactionInformationListFilters,
  });
  return axios({
    method,
    url,
  });
};

export const useGetAllTransactionsQuery = (payload) => {
  return useQuery(
    ['TRANSACTIONS_DATA', payload],
    () => FetchTransactionsData(payload),
    {
      enabled: Boolean(payload),
    }
  );
};

const FetchEmployeeData = (data) => {
  const method = 'GET';
  let url = appendQueryParams(`api/v1/admin/data-dashboard/employee-stats`, {
    ...data?.selectedEmployeeFilters,
    ...data?.employeeInformationListFilters,
  });

  return axios({
    method,
    url,
  });
};

export const useGetAllEmployeeQuery = (payload) => {
  return useQuery(
    ['EMPLOYEE_DATA', payload],
    () => FetchEmployeeData(payload),
    {
      enabled: Boolean(payload),
    }
  );
};

const GetAttendanceData = ({ code }) => {
  return axios({
    method: 'GET',
    url: `/api/v1/admin/manage-employee/salary/${code}`,
  });
};

export const useGetAttendanceData = (payload) => {
  return useQuery(
    ['ATTENDANCE_DATA', payload],
    () => GetAttendanceData(payload),
    {
      enabled: payload?.enabled,
    }
  );
};
